<template>
  <div class="page--lipstickbar product-detail">
    <div v-if="productDetail.isLoadingFinish.product" class="body">
      <div class="container-lipstickbar">
        <router-link
          v-if="productDetail.data.product.id_manufacturer != undefined"
          :to="`/lipstickbar/product-catalogue/${productDetail.data.product.id_manufacturer}`"
          class="back-to"
          >BACK</router-link
        >
        <a v-else href="" class="back-to" @click.prevent="$router.go(-1)">BACK</a>
        <template v-if="productDetail.data.product && Object.keys(productDetail.data.product).length > 0">
          <ProductDisplay
            :attributes-image="productDetail.data.attributesImage || []"
            :attributes-product="productDetail.data.attributesProduct || []"
            :group-active-product-attribute="groupActiveProductAttribute"
            :product="productDetail.data.product"
          />

          <div class="reviews">
            <h2 class="reviews__heading">
              <img src="/static/img/lipstickbar/reviews.svg" />USER'S REVIEWS<!--({{ totalReview > 10 ? '10' : (totalReview ? totalReview : '0') }})-->
            </h2>

            <div v-if="productDetail.isLoadingFinish.reviews" class="reviews__slide swiper-universal">
              <Reviews :reviews-product="reviewsProduct || []" />
            </div>

            <div v-else style="margin: 30px 0">
              <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
            </div>
          </div>
        </template>
        <template v-else>
          <div style="margin: 30px 0">
            <p class="text-center">Data not found.</p>
          </div>
        </template>
      </div>
    </div>
    <div v-else class="body">
      <div class="container-lipstickbar">
        <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalProductDetail from '@/mixins/product-detail';

export default {
  name: 'LipstickbarProductDetail',
  mixins: [GlobalProductDetail],
  computed: {
    productId() {
      return this.$route.params.product_id;
    },
    pageName() {
      return 'lipstick';
    },
  },
};
</script>

<style lang="scss">
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #f8f8f8;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}
</style>
